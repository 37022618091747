;import $ from 'jquery';
window.$ = window.jQuery = $;
import 'bootstrap';
import 'jquery.scrollto';
import '../vendor/ba-debug.min.js';

(function($){
//------------------------------------------------------------------------------------------------------------------------
    // Page constructor

    // Page constructor
    window.Page = {

//------------------------------------------------------------------------------------------------------------------------
    // Global variables

        // GTM
        dataLayer: window.dataLayer || [],

        // CSRF
        csrf_token: $('meta[name="csrf-token"]').attr('content'),

        // Responsive breakpoints
        mobileMaxWidth: 767.98,
        tabletMaxWidth: 991.98,

        // scrollTo
        scrollTo: {
            axis: 'y',
            duration: 300,
            offset: {
                left: 0,
                top: 0
            }
        },

        // Animation
        animation: 300,

//------------------------------------------------------------------------------------------------------------------------
    // Application

        // Set layout
        setLayout: function(){
            // Load scrollTo
            window.Page.loadScrollTo();

            // Load GTM
            //window.Page.loadGTM();
        },

        // Resize layout
        resizeLayout: function(){
        },

//------------------------------------------------------------------------------------------------------------------------
    // scrollTo

        // Load scrollTo
        loadScrollTo: function(){
            // CTA
            $(document).on('click', 'a[href^="#"], a[href^="' + window.location.href + '#"]', function(e){
                e.preventDefault();
                window.Page.scrollToHash(this.hash);
            });

            // On load
            $(window).on('load', function(e){
                var hash = window.location.hash;
                if(/^\#[0-9a-z_-]+$/gi.test(hash) && $(hash).is(':visible')){
                    window.Page.scrollToHash(hash);
                }
            });
        },

        // Scroll to content
        scrollToHash: function(hash, params){
            if(!$(hash).is(':visible')) return false;

            // Override settings
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, window.Page.scrollTo, params);

            // Scroll to content
            $(window).scrollTo(hash, settings);
            window.location.hash = hash;

            // Hide navbar on mobile view
            $('.navbar-collapse').collapse('hide');
        },

//------------------------------------------------------------------------------------------------------------------------
    // GTM Event

        // Load GTM
        loadGTM: function(){
            // CTA
            $(document).on('click', '[data-event="click"]', function(e){
                window.Page.sendEvent($(this).data('event-action'), $(this).data('event-category'), $(this).data('event-label'));
            });

            // On load
            $('[data-event="callback"]').each(function(i, target){
                window.Page.sendEvent($(target).data('event-action'), $(target).data('event-category'), $(target).data('event-label'));
            });
        },

        // GTM Event
        sendEvent: function(action, category, label){
            window.Page.dataLayer.push({
                event: action,
                eventAction: action,
                eventCategory: category,
                eventLabel: label
            });
        },

//------------------------------------------------------------------------------------------------------------------------

    };

//------------------------------------------------------------------------------------------------------------------------
    // Document ready

    // Document ready
    $(function(){
        // Set layout
        window.Page.setLayout();

        // Resize layout
        window.Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window loaded

    // Window loaded
    $(window).on('load', function(e){
        // Resize layout
        window.Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window resized or orientation changed

    // Window resized or orientation changed
    $(window).on('orientationchange resize', function(e){
        // Resize layout
        window.Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window scrolled

    // Window scrolled
    $(window).on('scroll', function(e){
        // Resize layout
        window.Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
})(jQuery);
